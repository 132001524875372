import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class LoadtestResolver implements Resolve<void> {
  constructor(private http: HttpClient) {}

  resolve(): Promise<void> {
    return this.http
      .get('/assets/loaderio-87039dd35990725edc6a630525f0b89f', { responseType: 'text' })
      .toPromise()
      .then((data) => {
        // Clear current content and display file content
        document.documentElement.innerHTML = data as string;
      });
  }
}
