import { NgModule, Component } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule, MatCheckboxModule, MatFormFieldModule, MatIconModule, MatInputModule, MAT_DIALOG_DATA } from '@angular/material';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { TranslateModule } from '@ngx-translate/core';
import { HttpModule, Http } from '@angular/http';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';
import { FuseSidebarModule, FuseConfirmDialogModule, FuseThemeOptionsModule } from '@fuse/components';
import { fuseConfig } from './fuse-config';
import { MaterialModule } from '../app/main/user/material.module';
import { MatDialogRef } from '@angular/material';
import { FakeDbService } from './fake-db/fake-db.service';
import { AppComponent } from './app.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { AppStoreModule } from './store/store.module';
import { LayoutModule } from './layout/layout.module';
import { ChartsModule } from 'ng2-charts';
import { NgxMatDrpModule } from 'ngx-mat-daterange-picker';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FuseHighlightComponent } from '@fuse/components/highlight/highlight.component';
declare var MarkerClusterer: any;
import 'markerclusterer';
import { ChartsComponent } from './main/charts/charts.component';
import { ReportingComponent } from './main/reporting/reporting.component';
import { LiveDashboardComponent } from './main/live-dashboard/live-dashboard.component';
import { sessionConfirmDialogModule } from '@fuse/components/confirm-dialog-session/confirm-dialog-sesssion.module';
import { ViewPodDialogModule } from '@fuse/components/view-pod-dialog/view-pod-dialog.module';
import { ViewDeliveryDocumentDialogModule } from '@fuse/components/view-delivery-document-dialog/view-delivery-document-dialog.module';
import { UpdateTripDialogModule } from '@fuse/components/update-trip-dialog/update-trip-dialog.module';
import { SelectJobDialogModule } from '@fuse/components/select-job-dialog/select-job-dialog.module';
import { ChooseFileDialogModule } from '@fuse/components/choose-file-dialog/choose-file-dialog.module';
import { FormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { NgxLoadingModule } from 'ngx-loading';
//import { ReportsComponent } from './main/reports/reports.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ProgressComponent } from './main/charts/progress/progress.component';
import { ChartProgressComponent } from './main/live-dashboard/chart-progress/chart-progress.component';
import { SecondaryFreightComponent } from './main/pages/authentication/EPW/secondary-freight/secondary-freight.component';
import { SatelliteAndCarrierComponent } from './main/pages/authentication/EPW/satellite-and-carrier/satellite-and-carrier.component';
import { HttpService } from './main/services/http.service';
import { AbortJobDialog } from './main/pages/authentication/EPW/abort_job/abort_job.component';
import { Ng2ImgMaxModule } from 'ng2-img-max';
import { SignaturePadModule } from 'angular2-signaturepad';
import { DeliveryDocumentsComponent } from './main/pages/authentication/EPW/delivery-documents/delivery-documents.component';
import { LoadTestComponent } from './main/load-test/load_test.component';
import { LoadtestResolver } from './main/load-test/load_test_resolver.component';

//import { CSVService } from './main/services/csv.service';

const appRoutes: Routes = [
    /* {
        path    : ':',
        children: [
            {
                path     : 'charts',
                component: ChartsComponent
            },]
    }, */
    {
        path: 'apps',
        loadChildren: './main/apps/apps.module#AppsModule'
    },
    {
        path: 'charts',
        component: ChartsComponent
    },
    /* {
        path        : 'reporting',
        component: ReportingComponent
    }, */
    /* {
        path        : 'reports',
        component   : ReportsComponent
    }, */
    {
        path: 'live-dashboard',
        component: LiveDashboardComponent
    },
    {
        path: 'wphht',
        loadChildren: './main/pages/pages.module#PagesModule'
    },
    {
        path: 'depot',
        loadChildren: './main/depot/depot.module#depotModule'
    },
    {
        path: 'reports',
        loadChildren: './main/reports/report.module#reportModule'
    },

    /* {                 
        path        : 'electronicpaperwork',
        loadChildren:'./main/electronicpaperwork/electronicpaperwork.module#electronicpaperworkModule'
    }, */
    {
        path: 'vehicle',
        loadChildren: './main/vehicle/vehicle.module#vehicleModule'
    },
    {
        path: 'planallocation',
        loadChildren: './main/assignDM/assignDM.module#assignDMModule'
    },
    {
        path: 'jobsummary',
        loadChildren: './main/jobsummary/jobsummary.module#JobSummaryModule'
    },
    {
        path: 'podQualityCheck',
        loadChildren: './main/podquality_check/podquality_check.module#PodQualityCheckModule'
    },
    {
        path: 'AssetManagement',
        loadChildren: './main/assetmanagement/assetmanagement.module#assetmanagementModule'
    },
    /* {
        path        : 'job', 
        loadChildren: './main/electronicpaperwork/electronicpaperwork.module#electronicpaperworkModule'
    }, */
    {
        path: 'user',
        loadChildren: './main/user/user.module#userModule'
    },
    {
        path: 'scheduler',
        loadChildren: './main/scheduler/scheduler.module#schedulerModule'
    },
    {
        path: 'bulk',
        component: SecondaryFreightComponent
    },
    {
        path: 'loaderio-87039dd35990725edc6a630525f0b89f', 
        component: LoadTestComponent, 
        resolve: { content: LoadtestResolver } },
    {
        path: 'bulk/:shipmentId',
        component: SecondaryFreightComponent
    },
    {
        path: 'document',
        component: DeliveryDocumentsComponent
    },
    {
        path: 'document/:shipmentId',
        component: DeliveryDocumentsComponent
    },
    {
        path: 'epw',
        component: SatelliteAndCarrierComponent
    },
    {
        path: 'epw/:deliveryNumber',
        component: SatelliteAndCarrierComponent
    },
    {
        path     : ':id',
        component: AbortJobDialog
    },
    {
        path: '**',
        redirectTo: '/wphht/auth/login-2'
    }
];
@NgModule({
    declarations: [
        AppComponent,
        FuseHighlightComponent,
        ChartsComponent,
        ReportingComponent,
        LiveDashboardComponent,
        ProgressComponent,
        ChartProgressComponent,
        SecondaryFreightComponent,
        SatelliteAndCarrierComponent,
        DeliveryDocumentsComponent,
        AbortJobDialog,
        LoadTestComponent
    ],
    imports:
        [
            BrowserModule,
            NgxPaginationModule,
            sessionConfirmDialogModule,
            ViewPodDialogModule,
            ViewDeliveryDocumentDialogModule,
            UpdateTripDialogModule,
            SelectJobDialogModule,
            ChooseFileDialogModule,
            FuseConfirmDialogModule,
            NgxChartsModule,
            NgxSpinnerModule,
            NgxLoadingModule.forRoot({}),
            ChartsModule,
            MaterialModule,
            FuseWidgetModule,
            NgxMatDrpModule,
            BrowserAnimationsModule,
            HttpClientModule,
            HttpModule,
            FormsModule,
            RouterModule.forRoot(appRoutes),

            TranslateModule.forRoot(),
            InMemoryWebApiModule.forRoot(FakeDbService, {
                delay: 0,
                passThruUnknownUrl: true
            }),

            // Material moment date module
            MatMomentDateModule,

            // Material
            MatButtonModule,
            MatIconModule,

            // Fuse modules
            FuseModule.forRoot(fuseConfig),
            FuseSharedModule,
            FuseSidebarModule,
            FuseThemeOptionsModule,

            // App modules
            LayoutModule,
            AppStoreModule,

            //bulk component changes
            Ng2ImgMaxModule,
            MatButtonModule,
            SignaturePadModule,
            FormsModule,
            NgxSpinnerModule,
            MatCheckboxModule,
            MatFormFieldModule,
            MatInputModule,
            MaterialModule
        ],
    providers: [
        AbortJobDialog, HttpService, DatePipe, LoadtestResolver,
        { provide: MAT_DIALOG_DATA, useValue: {} },
        { provide: MatDialogRef, useValue: {} },
        {provide: MatDialogRef,useValue: { close(dialogResult: any) { } }}
    ],
    entryComponents: [
        AbortJobDialog
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}
